<ion-card class="no-margin" class="col-1">
  <ion-card-header>
    <h2>
      @if (isPending) {
        <fa-icon [icon]="icons.hourglass" size="sm"></fa-icon>
        {{ 'shared.autoident.verification-is-pending' | translate }}
      } @else if (isUpdating) {
        <fa-icon [icon]="icons.hourglass" size="sm"></fa-icon>
        {{ 'shared.autoident.verification-status-is-updating' | translate }}
      } @else {
        <fa-icon [icon]="icons.fingerprint" size="sm"></fa-icon>
        {{ 'shared.autoident.verification-necessary' | translate }}
      }
    </h2>
  </ion-card-header>
  <ion-card-content>
    <div class="">
      <lib-auto-layout direction="vertical" [gap]="[10]">
        @if (isPending) {
          <ion-text>
            {{
              'shared.autoident.your-account-verification-is-pending-after-successful-verification-you-can-fully-use-your-account'
                | translate
            }}
          </ion-text>
        } @else if (isUpdating) {
          <ion-text>
            {{
              'shared.autoident.your-verification-status-is-being-updated-please-wait-a-moment'
                | translate
            }}
          </ion-text>
        } @else {
          <ion-text>
            {{
              'shared.autoident.your-account-is-not-verified-portal-functions-are-limited'
                | translate
            }}
          </ion-text>
          <ion-button
            expand="block"
            color="primary"
            class="p3"
            fill="white"
            color="black"
            (click)="showAutoIdentInformationModal()">
            <span>{{ 'shared.autoident.to-verification' | translate }}</span>
            <fa-icon slot="end" size="sm" [icon]="icons.arrowRight"></fa-icon>
          </ion-button>
        }
      </lib-auto-layout>
    </div>
  </ion-card-content>
</ion-card>
